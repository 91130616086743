export default class UiPlaceholder {
    constructor() {
        document.querySelectorAll('input[type=text], textarea, input[type=email]').forEach((callbackElement) => {
            let placeholder = "";

            callbackElement.addEventListener('click', function (event) {
                let element = event.target;
                placeholder = element.getAttribute('placeholder');
                element.setAttribute('placeholder', '');
            });

            callbackElement.addEventListener('blur', function (event) {
                let element = event.target;
                element.setAttribute('placeholder', placeholder);
                placeholder = "";
            });

        });
    }
}
new UiPlaceholder();