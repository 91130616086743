import 'bootstrap';
import '@fancyapps/ui';
import {Fancybox} from '@fancyapps/ui';

import './tool/UiPlaceholder';
import './include/init-jquery';

import './include/jquery-code';
import './include/sliders';
import './include/init-maskedinput';
import './include/init-datepicker';

import './block/hamburger';
import './block/form';
import './block/customFileLoader';


Fancybox.bind("[data-fancybox-video-frame]", {
	type: "iframe"
});