import {Fancybox} from "@fancyapps/ui";

export class Form {

    formElement;
    errors;

    constructor(formId) {

        this.formElement = document.querySelector(formId);

        this.init();
    }

    init() {
        this.addEvents();
    }

    addEvents() {
        if (this.formElement) {
            this.formElement.onsubmit = this.handleSubmitForm.bind(this);
        }
    }

    handleSubmitForm(e) {
        e.preventDefault();

        const form = e.target;

        this.validateForm(form);


        fetch(form.action, {
            body: new FormData(form),
            method: 'POST',
        })
            .then(response => response.json())
            .then(data => {


                this.resetErrors(form);

                if (data.success == false) {
                    if (data.errors) {

                        this.errors = data.errors;

                        for (let key in data.errors) {
                            this.renderError(form, key);
                        }

                    }
                }

                if (data.success == true) {

                    this.resetErrors(form);
                    this.resetForm(form);

                    let formName = 'default';

                    if (data.form === 'VACANCY') {
                        formName = data.form;
                    }

                    new Fancybox([
                        {
                            src: `/ajax/index.php?action=getSuccess&form=${formName}`,
                            type: "ajax",
                        },
                    ]);
                }
            });

    }

    validateForm(form) {
        return true;
    }

    renderError(target, key) {
        let field = target.querySelector(`.field-${key}`);

        if (field) {
            let formErrorElement = field.querySelector('.form-error');

            field.classList.add('has-error');

            if (formErrorElement) {
                formErrorElement.innerHTML = this.errors[key];
            }
        }
    }

    resetErrors(targetForm) {
        let formCommonErrorElementList = targetForm.querySelectorAll('.common-form-element');
        let formErrorElementList = targetForm.querySelectorAll('.form-error');

        if (formCommonErrorElementList) {
            formCommonErrorElementList.forEach(el => {
                el.classList.remove('has-error');
            });
        }

        if (formErrorElementList) {
            formErrorElementList.forEach(el => {
                el.innerHTML = '';
            });
        }
    }

    resetForm(targetForm) {
        targetForm.querySelectorAll("input[type=file]").forEach(el => {

            el.value = null;

            const e = new Event("change");
            el.dispatchEvent(e);
        });

        targetForm.reset();
    }
}