import Swiper from 'swiper/bundle';

let homeStatisticSlider, menuSlider, homeAboutSlider, moreNews, serviceSlider;

initServiceSlider();
initMenuSlider();
initStatisticSlider();
initHomeAboutSlider();
initMoreNews();
window.addEventListener('resize', function (event) {

    initServiceSlider();
    initMenuSlider();
    initStatisticSlider();
    initHomeAboutSlider();
    initMoreNews();

}, true);

function windowWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

function initServiceSlider() {

    serviceSlider = new Swiper(".service-slider", {
        slidesPerView: 2.3,
        spaceBetween: 10,
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
        },
        breakpoints: {
            610: {
                slidesPerView: 3,
            },
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
}

function initMoreNews() {

    if (document.querySelectorAll(".more-news-slider").length <= 0) {
        return false;
    }

    if (windowWidth() > 1100) {

        if (moreNews && moreNews.destroy && typeof moreNews.destroy === 'function') {
            moreNews.destroy(false);
        }

        return false;
    }


    moreNews = new Swiper(".more-news-slider", {
        slidesPerView: 1,
        breakpoints: {
            450: {
                slidesPerView: 2,
            },
        },
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
        },
    });

}

function initHomeAboutSlider() {

    let jelly = document.querySelector('.home-about-wrapper');

    if (jelly && jelly.length <= 0) {
        return false;
    }

    if (!document.querySelector(".home-about-slider")) {
        return false;
    }

    if (windowWidth() > 610) {

        if (jelly) {
            if (!jelly.classList.contains('jelly-slider-container')) {
                jelly.classList.add('jelly-slider-container');
            }
        }


        if (homeAboutSlider && homeAboutSlider.destroy && typeof homeAboutSlider.destroy === 'function') {
            homeAboutSlider.destroy(false);
        }

        return false;
    } else {
        if (jelly) {
            if (jelly.classList.contains('jelly-slider-container')) {
                jelly.classList.remove('jelly-slider-container');
            }
        }
    }


    homeAboutSlider = new Swiper(".home-about-slider", {
        slidesPerView: 1,
        breakpoints: {
            450: {
                slidesPerView: 2,
            },
            610: {
                slidesPerView: 3,
            },
            910: {
                slidesPerView: 4,
            },
        },
        // loop: true,
        spaceBetween: 10,
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
        },
    });
}

function initStatisticSlider() {

    if (!document.querySelector(".home-statistic-slider")) {
        return false;
    }

    if (windowWidth() > 610) {

        if (homeStatisticSlider && homeStatisticSlider.destroy && typeof homeStatisticSlider.destroy === 'function') {
            homeStatisticSlider.destroy();

            document.querySelectorAll('.home-statistic-item').forEach(el => {
                el.removeAttribute('style');
            });
        }

        return false;
    }

    homeStatisticSlider = new Swiper(".home-statistic-slider", {
        slidesPerView: 1.2,
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
        },
    });
}

function initMenuSlider() {

    if (document.querySelectorAll(".menu-slider").length <= 0) {
        return false;
    }

    if (windowWidth() > 1263) {

        if (menuSlider && menuSlider.destroy && typeof menuSlider.destroy === 'function') {
            menuSlider.destroy(false);
        }

        return false;
    }

    menuSlider = new Swiper(".menu-slider", {
        slidesPerView: 2.5,
        breakpoints: {
            470: {
                slidesPerView: 4.5,
            },
        }
    });
}

const homeSliderImages = new Swiper(".home-slider-images", {
    loop: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false
    },
    effect: 'fade',
    spaceBetween: 10,
});

const fraction = document.getElementById("pagination-control-fraction");
const homeSliderText = new Swiper(".home-slider-text", {
    loop: true,
    effect: 'fade',
    spaceBetween: 10,
    pagination: {
        el: ".swiper-pagination",
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    on: {
        init: (swiper) => {
            fraction.innerHTML = renderFraction(swiper);
        },
        slideChange: (swiper) => {
            fraction.innerHTML = renderFraction(swiper);
        }
    }
});

const eatSlider = new Swiper(".eat-slider", {
    slidesPerView: 'auto',
    autoplay: {
        delay: 2300,
    },
    pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
    },
});

function renderFraction(swiper) {

    let all = swiper.slides.length - 2;
    let pos = swiper.realIndex + 1;
    let htmlPos = pos;
    let htmlAll = all;

    if (pos < 10) {
        htmlPos = `0${pos}`;
    }

    if (all < 10) {
        htmlAll = `0${all}`;
    }
    return `<span class="current">${htmlPos}</span><span class="split">/</span><span class="all">${htmlAll}</span>`;
}


homeSliderText.controller.control = homeSliderImages;
homeSliderImages.controller.control = homeSliderText;