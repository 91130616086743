export default class CustomFileLoader {
    constructor(selector) {

        this.element = document.querySelector(selector);

        if (!this.element || this.element.length <= 0) {
            console.log(`CustomFileLoader: элемент по селектору ${selector} не найден.`);
            return;
        }


        this.arFiles = [];
        this.input = this.element.querySelector('input[type=file]');
        this.files = this.element.querySelector('.common-form-file-attached-container');


        if (!this.input || this.input.length <= 0) {
            console.log(`CustomFileLoader: input типа file не найден`);
            return;
        }

        if (!this.files || this.files.length <= 0) {
            console.log(`CustomFileLoader: элемента для files не найдено`);
            return;
        }


        this.events();
    }

    events() {
        this.input.addEventListener('change', this.onChangeFile.bind(this));
    }

    onChangeFile() {
        let newFiles = [];
        for (let index = 0; index < this.input.files.length; index++) {
            let file = this.input.files[index];
            newFiles.push(file);
            this.arFiles.push(file);
        }

        this.files.innerHTML = '';

        newFiles.forEach(file => {
            let fileElementContainer = this.createFileElementContainer(file);
            let fileElementInfo = this.createFileElementInfo(this.truncateText(file.name, 16));
            let fileElementAction = this.createFileElementAction();
            let imgClose = this.createCloseElement();


            fileElementAction.append(imgClose);
            fileElementContainer.append(fileElementInfo);
            fileElementContainer.append(fileElementAction);


            this.files.append(fileElementContainer);
        });
    }

    truncateText(text, length = 32) {

        if (text.length > length) {
            return text.substring(0, length) + "...";
        }

        return text;
    }

    createFileElementContainer(file) {
        let fileElementContainer = document.createElement("div");
        fileElementContainer.className = "common-form-file-attached";
        fileElementContainer.setAttribute('data-file', file);

        return fileElementContainer;
    }

    createFileElementInfo(text) {
        let fileElementInfo = document.createElement("span");
        fileElementInfo.className = "common-form-file-attached__info";
        fileElementInfo.innerHTML = text;

        return fileElementInfo;
    }

    createFileElementAction() {
        let fileElementAction = document.createElement("span");
        fileElementAction.className = "common-form-file-attached__action";
        fileElementAction.addEventListener('click', this.deleteFile.bind(this));

        return fileElementAction;
    }

    createCloseElement() {
        let imgClose = document.createElement("img");
        imgClose.src = '/assets/images/close.svg';

        return imgClose;
    }

    deleteFile(event) {
        event.preventDefault();
        let fileElement = event.target.parentNode.parentNode;
        let indexToRemove = this.arFiles.indexOf(fileElement.getAttribute('data-file'));
        fileElement.remove();
        this.arFiles.splice(indexToRemove, 1);
    }
}